import React from 'react'
import { getTextShort,getDateTime } from '../helpers/helper.novelty'
import { navigate } from "gatsby"
import {clearNoveltyAction} from '../redux/noveltiesDucks'
import { connect } from 'react-redux'

const NoveltyCard = (props) => {

    const hidenTags = props?.hidenTags
    const hidenAuthor = props?.hidenAuthor
    const isHome = props?.isHome
    const {novelty,tags} = props
    const dispatch = props.dispatch

    return  (
        <div onClick={(e) =>  e.preventDefault() + dispatch(clearNoveltyAction()) + navigate("/novedades/" + novelty.id + "/" + novelty.slug)} class={"novelty-card cursor-pointer " + (isHome ? 'home' : '')}>
           <img src={novelty.image} alt="" />
           <div className='div-content d-flex flex-column p-4 text-start'>
               <div className="div_texto">
                    <span className='date'>{getDateTime(novelty.date_published  )}</span>
                    <h2 className='title mt-5' >{getTextShort(novelty.title, isHome ? 100 : 100)}</h2>
                    <p className='description mt-3'>{getTextShort(novelty.headline,isHome ? 180 : 180)}</p>
               </div>
               <div className={"div_button col-12 text-center mt-auto d-lg-flex " + (!hidenTags ? "justify-content-between" : "justify-content-center")}>
                   <div className={"div_etiquetas flex-wrap " + (hidenTags && "d-none" )}>
                       {tags ? novelty.tags.map((tag,index) => (
                            index < 2 && <span className='pill btn btn-blue me-2 mt-1 mt-lg-0'> {tag.name} </span>
                       )):''}
                   </div>
                    <a onClick={(e) => e.preventDefault() + dispatch(clearNoveltyAction()) + navigate("/novedades/" + novelty.id + "/" + novelty.slug)} className="btn btn-blue mt-3 mt-lg-0">LEER NOVEDAD COMPLETA</a>
                </div>
               {novelty.author !== '' && <span className={'by-name' + (hidenAuthor ? ' d-none' : '')}>por {novelty.author}</span>}
           </div>
        </div>
    )
}

export default connect(state => ({
  }),null)(NoveltyCard);