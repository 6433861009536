export const getTextShort = (text,length) => {
    return text?.length > length ? text.substr(0,length) + '...' : text
}

export const getDateTime = (date,full = false) => {
    const Month = {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre'
    }
    if(date){
        const dateTime = date.split(':')
        let times = dateTime[0].split(' ') 
        times=times[0].split("-")
        return full ? [times[2].split("T")[0]] + " de " + Month[parseInt(times[1])]+ ' de ' + times[0] : Month[parseInt(times[1])] + " " + times[0]
    }
    return 'Enero 2020'
}

export const getFeatured = (novelties) => {
    if(novelties){
      return novelties.filter(item => item.featured === 'true' || item.featured === '1' || item.featured === true)
    }
}

export const getThree = (novelties) => {
    if(novelties){
        return novelties.slice(0,3)
    }
    return []
}

export const orderNovelties = (novelties) => {
    const noveltiesPair = novelties;
    for(let i = 0; i < noveltiesPair.length; i++){
        const date = noveltiesPair[i].date_published.split("T")[0].split("-")
        noveltiesPair[i].parseDate = new Date(date[0], date[1]-1, date[2])
    }
    return(noveltiesPair.sort((a, b) => b.parseDate.getTime() - a.parseDate.getTime()))
}

// Copy novelties array
const copyNovelties = (novelties) => {
    if (Array.isArray(novelties) && novelties?.length > 0) {
        let auxNovelties = [];
        for(let i = 0; i < novelties?.length; i++) {
            auxNovelties[i] = novelties[i]
        }
        return auxNovelties;
    } 
    return []
}

// First newest novelty, ordered by date_published prop
export const orderbyDate = (novelties) => {
    let auxNov = copyNovelties(novelties);
    return (auxNov && Array.isArray(auxNov) && auxNov.length > 0)
        ? auxNov?.sort((n1,n2) => new Date(n2?.date_published) - new Date(n1?.date_published))
        : []
}

// Not filter, order by featured first
export const orderbyFeatured = (novelties) => {
    let auxNov = copyNovelties(novelties);
    return (auxNov && Array.isArray(auxNov) && auxNov.length > 0)
        ? auxNov?.sort((n1,n2) => Number(n2?.featured) - Number(n1?.featured))
        : []
}